<template>
    <div class="contacts">
        <check-for-missing-integrations :integrations="['Freespee', 'Formstack']">
            <landing-page slot="no-integrations" image="/images/integrations/cta-messages/contacts.png" :headline="$t('contacts.onboarding.headline')" :description="$t('contacts.onboarding.description')">
                <hr>
                <call-and-form></call-and-form>
            </landing-page>

            <contacts></contacts>
        </check-for-missing-integrations>
    </div>
</template>

<script>
import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
import CallAndForm from '@/app/onboarding/components/integrations/CallAndForm'
import LandingPage from '@/app/onboarding/components/LandingPage'

import Contacts from './components/list/Contacts'

export default {
    components: {
        Contacts,
        CheckForMissingIntegrations,
        LandingPage,
        CallAndForm,
    }
}
</script>
