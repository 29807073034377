<template>
    <div class="list-group-item">
        <div class="row">
            <router-link :to="{name: 'frontend.contacts.view', params: {id: contact.id}}">
                <div class="col-sm-8">
                    <div class="profile-picture-container">
                        <template v-if="profilePicture">
                            <img class="profile-picture pull-left" :src="profilePicture.profilePicture">
                        </template>

                        <template v-else-if="contact.name">
                            <span class="first-letter pull-left">{{firstLetter}}</span>
                        </template>

                        <template v-else>
                            <img class="placeholder pull-left" src="/images/contacts/profile-picture-placeholder.svg">
                        </template>
                    </div>

                    <div class="contact-info">
                        <strong class="contactName">
                            <template v-if="contact.name">
                                {{contact.name}} <br>
                            </template>

                            <template v-else-if="contact.phone">
                                {{contact.phone}}
                            </template>

                            <template v-else-if="contact.email">
                                {{contact.email}}
                            </template>

                            <template v-else>
                                {{$t('contacts.unknown')}}
                            </template>
                        </strong>

                        <span class="text-muted" v-if="firstInquiry">
                            <template v-if="firstInquiry.source && isGhostIdentity">
                                {{ $t('trafficSources.' + firstInquiry.source) | capitalize }} <i class="fa fa-angle-right"></i>
                            </template>

                            {{inquiriesText}}

                            <template v-if="totalTurnover">
                                <i class="fa fa-angle-right"></i> {{ totalTurnover | fixedNumber(2) | numberFormat }} kr.
                            </template>
                        </span>
                    </div>
                </div>
            </router-link>

            <div class="col-md-4 col-sm-3 hidden-xs">
                <status class="status" :contact="contact"></status>
            </div>
        </div>

        <router-link :to="{name: 'frontend.contacts.view', params: {id: contact.id}}">
            <span class="fa fa-angle-right angle"></span>
        </router-link>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .list-group-item{
        position: relative;

        &:hover {
            cursor: pointer;
            background: #f5f5f5;
        }

        .row {
            > a {
                color: #000;
            }
        }

        .status {
            margin-top: 6px;
        }

        .angle {
            position: absolute;
            right: 20px;
            top: 24px;
            font-size: 20px;
            color: #888;
        }

        .contact-info {
            overflow: hidden;
        }

        .contactName {
            display: block;
            font-weight: 500;
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 2px;
        }

        .profile-picture-container {
            .profile-picture {
                width: 40px;
                height: 40px;
                border-radius: 999px;
                margin-right: 10px;
            }

            .first-letter {
                width: 40px;
                line-height: 40px;
                background: #e8e8e8;
                border-radius: 999px;
                text-align: center;
                margin-right: 15px;
                font-size: 16px;
                font-weight: bold;
                color: #777;
            }

            .placeholder {
                background: #eee;
                border-radius: 999px;
                margin-right: 10px;
                width: 40px;
                padding: 4px;
            }
        }

        .events {
            list-style: none;
            padding: 0;
            margin: 4px;

            li {
                float: left;
                font-weight: 200;

                span {
                    float: left;
                }

                .icon {
                    display: none;
                    font-size: 25px;
                    margin-left: 10px;
                }

                &:last-child {
                    margin-right: 0;

                    .icon {
                        display: inline-block;
                    }
                }

                &:after {
                    float: right;
                    font-family: 'Pe-icon-7-stroke';
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    font-size: 24pt;
                    content: "\e684";
                    line-height: 22px;
                    margin: 0 15px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
</style>

<script>
import Badge from '@/app/layout/components/Badge'
import Range from '@/app/layout/components/Range'
import Status from '@/app/contacts/components/Status'

import {mapGetters} from 'vuex'

const moment = require('moment')

export default {
    props: {
        contact: Object
    },
    computed: {
      ...mapGetters('ghost', ['isGhostIdentity']),

        inquiriesText() {
            if (!this.contact) {
                return;
            }

            var texts = [];

            if (this.calls.length > 0) {
                texts.push(this.calls.length + ' ' + $tc('contacts.call', this.calls.length));
            }

            if (this.forms.length > 0) {
                texts.push(this.forms.length + ' ' + $tc('contacts.emails', this.calls.length));
            }

            return texts.join(' ' + $t('contacts.and') + ' ');
        },

        firstInquiry() {
          if (!this.contact) {
            return;
          }

          if (!this.contact.inquiries && !this.contact.inquiries.length) {
            return;
          }

          const sortedByDateInquiries = this.contact.inquiries.sort((a, b) => {
            const dateA = moment(a.date.date)
            const dateB = moment(b.date.date)

            return dateA.isBefore(dateB)
          });

          return sortedByDateInquiries[0]
        },

        totalTurnover() {
          if (!this.contact) {
            return
          }

          const orderContacts = this.contact.orderContacts

          if (!orderContacts) {
            return
          }

          let totalTurnover = 0

          orderContacts.forEach((contact) => {
            const orders = contact.orders

            if (!orders) {
              return
            }

            orders.forEach((order) => {
              totalTurnover += order.amount
            })
          })

          return totalTurnover
        },

        calls() {
            return this.contact.inquiries.filter(function (inquiry) {
                return (inquiry.discr == 'call');
            });
        },

        forms() {
            return this.contact.inquiries.filter(function (inquiry) {
                return (inquiry.discr == 'form');
            });
        },

        firstLetter() {
            if (!this.contact.name) {
                return;
            }

            return this.contact.name.charAt(0)
        },
        profilePicture () {
            let profilePictures = this.contact.socialProfiles.filter(function (contact) {
                return contact.profilePicture !== null
            })
            return (profilePictures.length) ? profilePictures[0] : null
        }
    },
    components: {
        Badge,
        Range,
        Status
    }
}
</script>
