<template>
    <div class="contacts">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12" >
                    <card class="tour-step-1" :headline="$t('contacts.list.headline')" :description="$t('contacts.list.description')" icon="pe-7s-users">
                        <hr>

                        <search class="tour-step-2" :options="filters" @filters-updated="updateFilters"></search>

                        <br>

                        <div class="list-group">
                            <div class="list-group-item list-group-header">
                                <div class="row">
                                    <div class="col-sm-8">
                                        <strong>{{$t('contacts.table.contact')}}</strong>
                                    </div>

                                    <div class="col-md-4 col-sm-3 hidden-xs">
                                        <strong>{{$t('contacts.table.status')}}</strong>
                                    </div>
                                </div>
                            </div>

                            <div v-for="n in limit" v-if="!hasLoaded" class="list-group-item">
                                <div class="row">
                                    <div class="col-xs-6">
                                        <span class="mock">Anders Andersen</span>
                                    </div>

                                    <div class="col-md-3 col-xs-4 hidden-xs">
                                        <span class="mock">A becomes B And B is B</span>
                                    </div>

                                    <div class="col-md-4 col-xs-3 hidden-xs">
                                        <span class="mock">Status</span>
                                    </div>
                                </div>
                            </div>

                            <contact-list-item :contact="contact" v-for="(contact, i) in contacts" :key="i" v-if="hasLoaded"></contact-list-item>
                        </div>

                        <hr>

                        <pagination :total="total" :limit="limit" :page="page" v-on:paginate="paginate" class="pull-right"></pagination>

                        <div class="clearfix"></div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .alphabet{
        margin-left: 8px;
        letter-spacing: 2px;
    }
    .list-group-item {
        min-height: 68px;
    }

    .list-group-header strong{
        font-weight: 500;
        line-height: 38px;
        margin-top: 8px;
    }
</style>

<script>
    import Pagination from '@/app/layout/components/paginator/Pagination'
    import ContactListItem from '@/app/contacts/components/list/ListItem'

    const ContactService = require('@/services/contacts/ContactService')
    const FilterService = require('@/services/filters/FilterService')

    import Search from '@/app/shared/global/search/Search'

    import ContactStatusFilter from '@/services/filters/custom/ContactStatus'

    import tourMixin from '@/mixins/tours/tourMixin'
	import {mapGetters} from "vuex";

    export default {
        mixins: [tourMixin],

        props: {
            limit: {
                type: Number,
                default: 10
            }
        },

        watch: {
            page() {
                this.load()
            },

            hasLoaded() {
                if (!this.hasLoaded) {
                    return
                }

                this.startTourWhenReady()
            }
        },

        mounted() {
            this.load()
            this.loadFilters()
        },

		computed: {
			...mapGetters('customer', {
				customer: 'getCustomer'
			})
		},

        data() {
            return {
                page: 1,
                hasLoaded: false,
                total: 0,
                contacts: [],
                filters: {
                    query: null,
                    filters: []
                }
            }
        },

        methods: {
            load() {
                this.hasLoaded = false;

                let filters = FilterService.toRequest(this.filters,  ['name'])

                filters.push({
                    filters: [
                        {
                            key: 'name',
                            value: 'NULL',
                            operator: 'eq',
                            not: true
                        },
                        {
                            key: 'customer',
                            value: this.customer.id,
                            operator: 'eq'
                        }
                    ]
                });

                const data = {
                    includes: [
                        'inquiries',
                        'status',
                        'socialProfiles',
                        'orderContacts',
                        'orderContacts.orders'
                    ],
                    limit: this.limit,
                    page: this.page,
                    sort: [{
                        key: 'name',
                        direction: 'asc'
                    }],
                    filter_groups: filters
                }

                ContactService.findAll(data, (response) => {
                    const body = response.data

                    this.total = body.total
                    this.contacts = body.rows

                    this.hasLoaded = true
                });
            },

            updateFilters(filters) {
                this.filters = filters;

                this.page = 1;

                this.load();
            },

            paginate(page) {
                this.page = page;
            },

            loadFilters() {
                ContactStatusFilter((options, value) => {
                    this.filters.filters.push({
                        key: 'status',
                        empty: $t('filters.empty'),
                        value: value,
                        prefix: 'Status',
                        options: options
                    })
                }, null)
            },

            startTourWhenReady() {
                let options = {
                    disableInteraction: false,
                    steps: [
                        {
                            element: '.tour-step-1',
                            intro: '<h5>Din personlige kontaktbog</h5><p>Her kan du nemt og hurtigt finde oplysningerne på dem, der har kontaktet din virksomhed. OP\'N sørger selv for at opdatere din kontaktbog.</p>',
                            position: 'top',
                        },
                        {
                            element: '.tour-step-2',
                            intro: '<h5>Find en kontakt</h5><p>Brug denne funktion til at søge efter navn, telefonnummer eller email for at finde en kontakt. Vælg ‘Status’, hvis du har inddelt dine kontakter i flere grupper</p>',
                            position: 'top',
                        },
                    ]
                }

                if (this.isOnboarding) {
                    options.steps.push({
                        element: '.tour-step-integration',
                        intro: '<h5>Fortsæt det gode arbejde</h5><p>Du har nu opsat din første integration i OP\'N. Klik på integrationssiden for at opsætte den næste.</p>',
                    })
                }

                this.startTour('contacts', options)
            }
        },

        components: {
            Pagination,
            ContactListItem,
            Search
        }
    }
</script>
