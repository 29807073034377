<template>
    <div class="contact container-fluid">
        <loader v-if="!hasLoaded"></loader>

        <template v-if="hasLoaded">
            <div class="row">
                <div class="col-md-8 col-sm-7">

                    <div class="profile">
                        <card>
                            <img v-if="profilePicture" :src="profilePicture.profilePicture"
                                 class="img-circle profile-picture pull-left">

                            <span v-else-if="contact.name" class="first-letter pull-left">{{firstLetter}}</span>

                            <img src="/images/contacts/profile-picture-placeholder.svg"
                                 class="img-circle placeholder profile-picture pull-left" v-else>

                            <div>
                                <h2>
                                    <template v-if="contact.name">{{contact.name}}</template>
                                    <template v-else><span class="text-muted">{{$t('contacts.unknown')}}</span>
                                    </template>
                                </h2>

                                <p class="contact-info">
                                    <i class="pe-7s-phone phone pull-left"></i>

                                    <span class="pull-left">
                                        <template v-if="contact.phone">{{contact.phone}}</template>
                                        <template v-else><span
                                                class="text-muted">{{$t('contacts.notFilled')}}</span></template>
                                    </span>

                                    <i class="pe-7s-mail-open-file mail pull-left"></i>

                                    <span class="pull-left">
                                        <template v-if="contact.email">{{contact.email}}</template>
                                        <template v-else><span
                                                class="text-muted">{{$t('contacts.notFilled')}}</span></template>
                                    </span>
                                </p>
                            </div>

                            <div class="clearfix"></div>
                            <small class="text-muted first-inquiry">
                                {{$t('contacts.view.firstInquiry')}}: {{contact.created.date | moment("DD MMMM YYYY")}}
                            </small>
                        </card>
                    </div>

                    <div class="timeline">
                        <div class="entry" v-for="(entry, index) in timeline" :class="{danger: isMissed(index)}">
                            <template v-if="entry.type == 'inquiry'">
                                <div class="data">
                                    <span class="date hidden-xs">
                                        {{entry.date | moment("calendar")}}
                                    </span>

                                    <span class="date visible-xs">
                                        <span class="label label-default">{{entry.date | moment("DD MMM") }}</span>
                                    </span>

                                    <span class="icon">
                                        <span v-if="entry.object.discr == 'call'" class="fa fa-phone fa-fw"></span>
                                        <span v-else-if="entry.object.discr == 'form'"
                                              class="fa fa-pencil fa-fw"></span>
                                    </span>
                                </div>

                                <div class="content">
                                    <card>
                                        <div class=" hidden-xs">
                                            <template v-if="entry.object.discr == 'call'">
                                                <call :inquiry="entry.object"></call>
                                                <strong>{{$t('contacts.view.duration')}}: </strong>{{entry.object.duration | duration}}
                                            </template>

                                            <template v-if="entry.object.discr == 'form'">
                                                <email :inquiry="entry.object"></email>
                                            </template>
                                        </div>

                                        <div class="panel-body visible-xs">
                                            <template v-if="entry.object.discr == 'call'">
                                                <template v-if="entry.object.answered">
                                                    <strong>{{$t('contacts.answered')}} {{$t('contacts.from')}} {{entry.object.anum}}</strong>
                                                </template>

                                                <template v-else>
                                                    <strong>{{$t('contacts.missed')}} {{$t('contacts.from')}} {{entry.object.anum}}</strong>
                                                </template>
                                            </template>

                                            <template v-if="entry.object.discr == 'form'">
                                                <strong>{{entry.object.formName}}</strong>
                                            </template>
                                        </div>
                                    </card>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-5">
                    <card>
                        <div class="row">

                            <div class="col-md-12 col-sm-12 col-lg-6">
                                <span class="text-muted">Status</span>
                                <status :contact="contact" class="status-dropdown"></status>
                            </div>
                            <div class="col-md-12 col-sm-12 col-lg-5 col-lg-offset-1" v-if="contact.type">
                                <span class="text-muted">{{$t('contacts.view.type')}}</span>

                                <p style="margin-top: 16px;">
                                    <span v-if="contact.type == 'company'"> {{$t('contacts.company')}}</span>
                                    <span v-else> {{$t('contacts.person')}}</span>
                                </p>
                            </div>

                            <template v-if="socialProfiles.length > 0">
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-12">
                                    <span class="text-muted">{{$t('contacts.view.socialProfiles')}}</span>

                                    <div class="online-profile" :class="{'hover': socialProfile.url !== null}"
                                         v-for="socialProfile in socialProfiles">
                                        <template v-if="socialProfile.url !== null">
                                            <a :href="socialProfile.url" class="online-profile-link" target="_blank"
                                               rel="noopener noreferrer">
                                                <div class="online-profile-picture-container pull-left">
                                                    <img v-if="socialProfile.profilePicture !== null"
                                                         :src="socialProfile.profilePicture"
                                                         class="online-profile-picture">
                                                    <img src="/images/contacts/profile-picture-placeholder.svg"
                                                         class="online-profile-picture" v-else>

                                                    <div class="logo-overlay"></div>

                                                    <img class="logo" v-bind:class="socialProfile.type">
                                                </div>

                                                <div class="social-info">
                                                    <p class="name">{{contact.name}}</p>
                                                    <small class="text-muted ellipsis" v-if="socialProfile.title">
                                                        {{socialProfile.title}}
                                                    </small>
                                                </div>
                                            </a>
                                        </template>

                                        <template v-else>
                                            <div class="online-profile-picture-container pull-left">
                                                <img v-if="socialProfile.profilePicture !== null"
                                                     :src="socialProfile.profilePicture" class="online-profile-picture">
                                                <img src="/images/contacts/profile-picture-placeholder.svg"
                                                     class="online-profile-picture" v-else>

                                                <div class="logo-overlay"></div>

                                                <img class="logo" :class="socialProfile.type">
                                            </div>

                                            <div class="social-info">
                                                <p class="name">{{contact.name}}</p>
                                                <small class="text-muted ellipsis" v-if="socialProfile.title">
                                                    {{socialProfile.title}}
                                                </small>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <template v-show="contact.address">
                                <template v-if="firstInquiry && (firstInquiry.sourceSlug || totalTurnover) && isGhostIdentity">
                                    <div class="col-md-12">
                                        <hr>
                                    </div>

                                    <div class="col-lg-6" v-if="firstInquiry.sourceSlug">
                                        <span class="text-muted">{{$t('contacts.firstSource')}}</span>
                                        <p>{{$t('trafficSources.' + firstInquiry.sourceSlug)}}</p>
                                    </div>

                                    <div class="col-lg-6" v-if="totalTurnover">
                                        <span class="text-muted">{{$t('contacts.turnover')}}</span>
                                        <p>{{totalTurnover}} kr.</p>
                                    </div>
                                </template>

                                <template v-if="contact.address">
                                    <div class="col-md-12">
                                        <hr/>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="group">
                                            <span class="text-muted">{{$t('contacts.view.address')}}</span>

                                            <p>
                                                {{contact.address.street}}<br/>
                                                {{contact.address.zipCode}}
                                                <template v-if="contact.address.country">
                                                    {{contact.address.country.name}}
                                                </template>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="map"></div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </card>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    $borderColor: #ccc;
    $borderSize: 3px;

    $redBorderColor: #ec8686;
    $greenMailColor: #00c49f;
    $bluePhoneColor: #00438f;

    .contact {
        .map {
            width: 100%;
            height: 200px;

            margin-top: 20px;
        }

        .status-dropdown {
            margin-top: 10px;
        }

        .online-profile.hover:hover {
            border: 1px solid #4096ee;
        }

        .online-profile {
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            overflow: hidden;
            margin-top: 8px;

            a {
                color: inherit;
            }

            .online-profile-picture-container {
                position: relative;
                margin-right: 15px;

                .logo-overlay {
                    background-color: white;
                    opacity: 0.8;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 24px;
                }
                .online-profile-picture {
                    width: 75px !important;
                    height: 75px !important;
                }

                .logo {
                    position: absolute;

                    &.linkedin {
                        bottom: 4px;
                        left: 13px;
                        width: 51px;
                        content: url('/images/contacts/linkedin.svg');
                    }

                    &.facebook {
                        bottom: 6px;
                        left: 12px;
                        width: 53px;
                        content: url('/images/contacts/facebook.svg');
                    }
                }
            }

            .social-info {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 20px;
            }

            .name {
                margin: 16px 0 -2px;
                padding: 0;
            }
        }

        .profile {
            position: relative;

            .profile-picture {
                border: 5px solid #e3e3e3;
                margin-right: 30px;
                width: 78px;
                height: 78px;
                background-color: #f5f5f5;

                &.placeholder {
                    padding: 4px;
                }
            }
            .first-letter {
                border: 5px solid #e3e3e3;
                margin-right: 30px;
                width: 78px;
                height: 78px;
                background-color: #e3e3e3;
                line-height: 70px;
                border-radius: 999px;
                text-align: center;
                font-size: 25px;
                font-weight: bold;
                color: #777;
            }

            h2 {
                display: inline-block;
                margin: 12px 0 12px 0;
                padding: 0;
                font-size: 18px;
                font-weight: 400;
            }

            .contact-info {
                font-size: 14px;

                > span {
                    margin-top: 2px;
                    margin-left: 10px;
                }

                .phone {
                    font-size: 26px;
                    color: $bluePhoneColor;
                    margin-left: -4px;
                }

                .mail {
                    font-size: 26px;
                    color: $greenMailColor;
                    margin-left: 15px;
                }
            }

            .first-inquiry {
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }

        .timeline {
            padding-left: 140px;

            .entry {
                position: relative;

                .data {
                    float: left;
                    margin-top: -4px;
                    position: absolute;

                    .date {
                        position: absolute;
                        left: -130px;
                        top: 15px;
                        width: 95px;
                        text-align: right;
                    }

                    .icon {
                        position: absolute;
                        left: -18px;
                        top: 4px;
                        border: $borderSize solid $borderColor;
                        border-radius: 50%;
                        padding: 8px 8px;
                        background-color: #fff;
                        z-index: 10;
                    }
                }

                .content {
                    padding-left: 40px;
                    border-left: $borderSize solid $borderColor;
                    padding-bottom: 30px;

                    .panel {
                        margin-bottom: 0;
                    }
                }

                &:first-child {
                    .data {
                        margin-top: -4px;
                        position: relative;
                    }

                    .content {
                        padding-top: 4px;
                    }
                }

                &:last-child {
                    .content {
                        padding-bottom: 25px;
                        border-color: transparent !important;
                    }
                }

                &.danger {
                    .content {
                        border-color: $redBorderColor;
                    }

                    .data {
                        .date {
                            color: darken($redBorderColor, 40%);
                        }

                        .icon {
                            color: darken($redBorderColor, 40%);
                            border-color: $redBorderColor;
                        }
                    }

                    .panel.panel-default {
                        color: darken($redBorderColor, 40%);
                        border-color: $redBorderColor;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .timeline {
                padding-left: 70px;
                margin-top: 20px;

                .entry {
                    .data {
                        .date {
                            left: -70px;
                            font-size: 14px;
                            width: 40px;
                            text-align: left;
                            top: 4px;
                        }

                        .icon {
                            width: 10px;
                            height: 10px;
                            left: -9px;
                            background-color: $borderColor;

                            .fa {
                                display: none;
                            }
                        }
                    }

                    &.danger {
                        .data {
                            .icon {
                                background-color: $redBorderColor;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import DatePicker from '@/app/datepicker/components/Datepicker'
    import Paginator from '@/app/layout/components/paginator/Pagination'
    import Email from '@/app/inquiries/components/list/Form'
    import Call from '@/app/inquiries/components/list/Call'
    import Card from '@/app/shared/components/Card'
    import Badge from '@/app/layout/components/Badge'
    import Status from '@/app/contacts/components/Status'

    import {mapGetters} from 'vuex'

    const moment = require('moment');
    const ContactService = require('@/services/contacts/ContactService');

    export default {
        name: 'Contact',

        data() {
            return {
                hasLoaded: false,
                contact: null,
                map: null,
                items: []
            }
        },

        computed: {
            timeline() {
                return this.items.sort(function (a, b) {
                    return b.moment.utc() - a.moment.utc();
                });
            },
            socialProfiles () {
                return this.contact.socialProfiles.filter(function (contact) {
                    return contact.type === 'facebook' || contact.type === 'linkedin'
                })
            },
            profilePicture () {
                let profilePictures = this.contact.socialProfiles.filter(function (contact) {
                    return contact.profilePicture !== null
                })
                return (profilePictures.length) ? profilePictures[0] : null
            },
            firstLetter() {
                if (!this.contact.name) {
                    return;
                }

                return this.contact.name.charAt(0)
            },
          ...mapGetters('ghost', ['isGhostIdentity']),

            timeline() {
                return this.items.sort(function (a, b) {
                    return b.moment.utc() - a.moment.utc();
                });
            },

            firstInquiry() {
                if (!this.contact) {
                    return;
                }

                if (!this.contact.inquiries && !this.contact.inquiries.length) {
                    return;
                }

                const sortedByDateInquiries = this.contact.inquiries.sort((a, b) => {
                    const dateA = moment(a.date.date)
                    const dateB = moment(b.date.date)

                    return dateA.isBefore(dateB)
                });

                return sortedByDateInquiries[0]
            },

            totalTurnover() {
                if (!this.contact) {
                    return
                }

                const orderContacts = this.contact.orderContacts

                if (!orderContacts) {
                    return
                }

                let totalTurnover = 0

                orderContacts.forEach((contact) => {
                    const orders = contact.orders

                    if (!orders) {
                        return
                    }

                    orders.forEach((order) => {
                        totalTurnover += order.amount
                    })
                })

                return totalTurnover
            },
        },

        mounted() {
            this.load();
        },

        methods: {
            load() {
                const id = this.$route.params.id;

                let data = {
                    includes: [
                        'status',
                        'address',
                        'address.country',
                        'inquiries',
                        'socialProfiles',
                        'orderContacts',
                        'orderContacts.orders'
                    ]
                };

                ContactService.find(id, data, (response) => {
                    let body = response.data;

                    this.contact = response.data;
                    this.hasLoaded = true;

                    this.contact.inquiries.forEach((inquiry) => {
                        this.items.push({
                            type: 'inquiry',
                            date: new moment(inquiry.date.date),
                            moment: new moment(inquiry.date.date),
                            object: inquiry
                        });
                    });

                    setTimeout(this.loadMap, 150);
                });
            },

            loadMap() {
                if (!this.contact.address || !this.contact.address.longitude) {
                    return;
                }
                let address = this.contact.address;
                let canvas = this.$el.querySelector('.map');

                this.map = new google.maps.Map(canvas, {
                    zoom: 1,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });

                const bounds = new google.maps.LatLngBounds();

                let marker = new google.maps.Marker({
                    position: new google.maps.LatLng(address.latitude, address.longitude),
                    map: this.map
                });

                bounds.extend(marker.position);

                this.map.fitBounds(bounds);

                setTimeout(() => {
                    this.map.setZoom(13);
                }, 250);
            },

            isMissed(index) {
                var entry = this.items[index];

                if (!entry || entry.type != 'inquiry') {
                    return false;
                }

                if (entry.object.discr != 'call') {
                    return false;
                }

                return (!entry.object.answered);
            }
        },

        components: {
            Loader,
            DatePicker,
            Call,
            Email,
            Card,
            Badge,
            Status
        }
    }
</script>
